<template>
  <vs-prompt
    title="Compose Email"
    :active.sync="active"
    accept-text="Send"
  >
    <div class="con-exemple-prompt">
      <vs-input
        v-model="to"
        size="large"
        label-placeholder="To"
        class="w-100 mb-4"
      />
      <vs-input
        v-model="subject"
        size="large"
        label-placeholder="Subject"
        class="w-100 mb-4"
      />
      <vs-input
        v-model="cc"
        size="large"
        type="cc"
        label-placeholder="cc"
        class="w-100 mb-4"
      />
      <vs-textarea
        v-model="message"
        placeholder="Type message"
        class="w-100 mb-4"
        counter="200"
        :counter-danger.sync="counterDanger"
      />
      <vs-upload
        v-model="img"
        action="https://jsonplaceholder.typicode.com/posts/"
        text="Attachments"
        :show-upload-button="false"
      />
    </div>
  </vs-prompt>
</template>
<script>
export default {
  props: {
    composePrompt: {
      type: Boolean,
      default: false
    },
    close: {
      type: String
    },
    acceptAlert: {
      type: String
    }
  },
  data: () => ({
    to: '',
    subject: '',
    cc: '',
    message: '',
    img: '',
    counterDanger: false
  }),
  computed: {
    active: {
      get () { return this.composePrompt },
      set (value) { this.$emit('update:composePrompt', value) },
    },
  }
}
</script>